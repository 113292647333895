import { createStitches } from '@stitches/react';

const { styled, theme, globalCss, config, css, keyframes } = createStitches({
  prefix: 'nwwb',
  media: {
    sm: '(max-width: 640px)',
    md: '(max-width: 768px)',
    lg: '(min-width: 1024px)',
    xlg: '(min-width: 1440px)',
  },
  theme: {
    colors: {
      primary: '$brandEmpreendedor',
      secondary200: '$red200',
      secondary300: '$red300',
      secondary400: '$red400',
      secondary700: '$red700',
      white: '#fff',
      black: '#000',
      gray100: '#f8f8f8',
      gray200: '#f2f2f3',
      gray300: '#eaeaeb',
      gray400: '#d5d5d8',
      gray500: '#cacace',
      gray600: '#808088',
      gray700: '#45454a',
      gray800: '#141415',
      brandFinanciador: '#378894',
      green200: '#f1f8f9',
      green300: '#c9e3e8',
      green400: '#a3ced7',
      green500: '#67acbb',
      green600: '#3e6c75',
      green700: '#2e5158',
      green800: '#264349',
      green900: '#0f1b1e',
      brandEmpreendedor: '#f75e4c',
      red200: '#fbeeec',
      red300: '#f4ccc6',
      red400: '#eb9a8e',
      red500: '#e77a69',
      red600: '#d73a25',
      red700: '#c53521',
      red800: '#6b1c10',
      red900: '#110401',
    },
    space: {
      8: '0.5rem',
      16: '1rem',
      24: '1.5rem',
      32: '2rem',
      40: '2.5rem',
      48: '3rem',
      56: '3.5rem',
      64: '4rem',
      72: '4.5rem',
      80: '5rem',
      88: '5.5rem',
      96: '6rem',
      104: '6.5rem',
      112: '7rem',
      120: '7.5rem',
      128: '8rem',
      136: '8.5rem',
      144: '9rem',
      152: '9.5rem',
      150: '10rem',
    },
    fonts: {
      inter: 'Inter, sans-serif',
    },
    fontSizes: {
      h1: '4.25rem',
      h2: '2.625rem',
      h3: '1.625rem',
      h4: '1rem',
      paragraph: '1rem',
      psmall: '0.875rem',
      caption: '0.75rem',
    },
    radii: {
      xs: '6px',
      sm: '8px',
      md: '12px',
      lg: '14px',
      xl: '16px',
      xxl: '24px',
    },
    borderStyles: {
      default: '1px solid #EAEAEB',
    },
    shadows: {
      default: '0px 2px 15px -2px rgba(0, 0, 0, 0.15)',
    },
  },
});

const globalStyles = globalCss({
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },
  'html,body': {
    height: '100%',

    fontFamily: '$inter',
  },
});

export { styled, theme, globalStyles, config, css, keyframes };
