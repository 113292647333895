import { styled } from '../stitches.config'
import {ReactComponent as ImgLogo} from '../assets/logoVerde.svg'
import Empreendedor from '../assets/imgEmpreendedor.png'
import Financiador from '../assets/imgFinanciador.png'

export const Description = styled('span', {
  fontWeight: '500',
  fontSize: '1.2rem',
  color: '#ffffff',
  textAlign: 'center'
})

export const Container = styled('div', {
  width: '100vw', 
  height: '100vh', 
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',

  '@sm': {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  '@md': {
    display: 'block',
    width: '100%',
    height: '100%',
  }
})

export const Logo = styled(ImgLogo, {})

export const Content = styled('div', {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',

  '@sm': {
    display: 'block',
    position: 'relative',
  },
  '@md': {
    display: 'block',
    position: 'relative',
  },

  '@xlg': {
    margin: '0 68px',
  }
})

export const Flex = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  minHeight: '150px',
  width: '100%',

  '@sm': {
    minHeight: 'auto',
  },

})

export const EmpreendedorImg = styled('div', {
  position: 'relative',
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.43)), url(${Empreendedor})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  borderRadius: '24px',
  height: '80dvh',
  width: '65dvh',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',

  padding: '1.5rem',
 

  '@sm': {
    height: '30dvh',
    width: 'calc(65dvh - 1.5rem)',
    top: '90px',
  },
  '@md': {
    height: '32dvh',
    width: 'calc(80dvh - 1.5rem)',
    top: '80px',
  },
  '@lg': {
    height: '55dvh',
    width: 'calc(50dvh -  1.5rem)',
  },
  '@xlg': {
    height: '80dvh',
    width: 'calc(65dvh - 1.5rem)',
   
  }
})

export const FinanciadorImg = styled('div', {
  position: 'relative',
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.43)), url(${Financiador})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  borderRadius: '24px',
  height: '80dvh',
  width: '65dvh',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',

  padding: '1.5rem',
  
  '@sm': {
    height: '30dvh',
    width: 'calc(65dvh - 1.5rem)',
    top: '130px',
  },

  '@md': {
    height: '32dvh',
    width: 'calc(80dvh -  1.5rem)',
    top: '98px',
  },
  '@lg': {
    height: '55dvh',
    width: 'calc(50dvh - 1.5rem)',
  },
  '@xlg': {
    height: '80dvh',
    width: 'calc(65dvh - 1.5rem)',
    
  }
})

export const Button = styled('button', {
  width: '100%',
  height: '63.15px',
  background: '#FFFFFF',
  borderRadius: '14px',
  border: 'none',

  fontWeight: '600',
  fontSize: '1.3rem',

  transition: '.7s',
  cursor: 'pointer',

  '@sm': {
    width: '270px',
    height: '50px',
    fontSize: '14px',
  },

  variants:{
    color: {
      financiador: {
        color: '#008290',
      },
      empreendedor: {
        color: '#F75E4C',
      }
    },

    hover: {
      financiador: {
        '&:hover': {
          background: '#C9E3E8',
          color: '#264349',
        }
      },
      empreendedor: {
        '&:hover': {
          background: '#F4CCC6',
          color: '#C53521',
        }
      }
    }
  }
})

export const ContentAssign = styled('div', {
  marginLeft: '0 0 0 115px',
  '@sm': {
    margin: '20px',
  },
  '@md': {
    margin: '20px',
  },

  '@lg': {
    margin: '0 0 0 34px',
  },
  '@xlg': {
    margin: '0 0 0 115px',
  },

  variants: {
    mobilefooter: {
      footer: {
        '@sm': {
          position: 'absolute',
          bottom: '0',
        },
        '@md': {
          position: 'absolute',
          bottom: '0',
        }
      }
    }
  }
})

export const ContentTextImg = styled('div',{})

export const Text = styled('span', {
  display: 'flex',
  flexDirection: 'column', 
  fontStyle: 'normal',
  variants: {
    tipotexto: {
      pergunta: {
        fontWeight: '700',
        fontSize: '35px',
        '@lg': {
          fontSize: '30px',
        }
      },
      textoauxiliar: {
        fontWeight: '400',
        fontSize: '20px',
        '@lg': {
          fontSize: '16px',
        }
      },
      assinatura: {
        fontWeight: '500',
        fontSize: '16px',
      },
    },
    position: {
      mobiletitle: {
        '@sm': {
          position: 'absolute',
          top: '0',
          margin: '0 20px',
          fontSize: '25px',
        },
        '@md': {
          position: 'absolute',
          top: '0',
          margin: '0 20px',
          fontSize: '25px',
        }
        
      },
      mobilesubtitle: {
        '@sm': {
          position: 'absolute',
          top: '30px',
          margin: '0 20px',
          fontSize: '1rem',
        },
        '@md': {
          position: 'absolute',
          top: '30px',
          margin: '0 20px',
          fontSize: '1rem',
        }
      },
    }
  }
})


export const ContentArrows = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '24px',

  variants: {
    position: {
      mobile: {
        '@sm': {
          display: 'none',
        },
        '@md': {
          display: 'none',
        }
      }
    },
  }
  
})







