const defaultConfig = {
  IS_STS_AUTHORITY: '',
  IS_CLIENT_ID: '',
  IS_CLIENT_ROOT: '',
  IS_CLIENT_SCOPE: '',

  URL_FINANCIADOR: '',
  URL_EMPREENDEDOR: '',
  URL_EMPREENDEDOR_PWA: '',
}

export type Config = typeof defaultConfig

declare global {
  interface Window {
    config: Config
  }
}

const CONFIG: Config =
  window && window.config
    ? window.config
    : defaultConfig

export default CONFIG 
