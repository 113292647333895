
import React from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { ReactComponent as ArrowRight } from '../assets/arrowVerde.svg'
import { ReactComponent as ArrowLeft} from '../assets/logoVermelha.svg'
import Config from '../config'

import { 
  Container, 
  Logo,
  Content, 
  EmpreendedorImg,
  FinanciadorImg,
  Button,
  ContentAssign,
  ContentTextImg, 
  Text, 
  ContentArrows,
  Description,
  Flex
} from './style'

const dataAtual = new Date()
const anoAtual = dataAtual.getFullYear()

export const NovoPortal = () => {
  return(
    <Router>
      <Container>
        <ContentAssign>
          <Logo />
        </ContentAssign>
        <Content>
          <Link to={Config.URL_EMPREENDEDOR} style={{display: 'flex', justifyContent: 'center', textDecoration: 'none'}}>
            <EmpreendedorImg>
              <Flex>
                <Button color='empreendedor' hover='empreendedor'>
                  Empreendedor
                </Button>
                <Description>Sou Empresa e quero crédito</Description>
              </Flex>
            </EmpreendedorImg>
          </Link>

          <ContentTextImg>
            <Text tipotexto='pergunta' position='mobiletitle'>Quem é você?</Text>
            <Text tipotexto='textoauxiliar' position='mobilesubtitle'>selecione a opção desejada</Text>
            <ContentArrows position='mobile'>
              <ArrowLeft />
              <ArrowRight />
            </ContentArrows>
          </ContentTextImg>

          <Link to={Config.URL_FINANCIADOR} style={{display: 'flex', justifyContent: 'center', textDecoration: 'none'}}>
            <FinanciadorImg>
              <Flex>
                <Button color='financiador' hover='financiador'>
                  Instituição Financeira
                </Button>  
                <Description>Sou uma Instituição Financeira e quero conceder crédito a outras empresas</Description>
              </Flex>
            </FinanciadorImg>
          </Link>
        </Content>

        <ContentAssign mobilefooter='footer'>
          <Text tipotexto='assinatura'>&#169; Finplace { anoAtual }</Text>
        </ContentAssign>
      </Container>
    </Router>
  )
} 