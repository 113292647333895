
import React from 'react'
import { NovoPortal } from './NovoPortal'

function App() {
  return (
    <NovoPortal />
  )
}

export default App
 